<template>
  <div>
    <nav-bar />
    <div id="wrapper" :class="classObject">
      <sidebar
        :side="leftClassName + ' l'"
        :class="patternLab ? 'patternLab' : ''"
        :display="showLeftBar"
        :slideSideBarIn="slideLeftBarIn"
      >
        <slot name="left" />
      </sidebar>
      <b-container fluid id="main-content" :class="mainContentClass">
        <slot />
      </b-container>
      <sidebar
        :side="rightClassName + ' r'"
        :display="showRightBar"
        :slideSideBarIn="slideRightBarIn"
      >
        <slot name="right" />
      </sidebar>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/navs/MainNavigation.vue";
import Sidebar from "../components/Sidebar.vue";
export default {
  name: "SidebarLayout",
  components: { Sidebar, NavBar },
  props: {
    showRightBar: {
      type: Boolean,
      default: true,
    },
    slideLeftBarIn: {
      type: Boolean,
      default: undefined,
    },
    showLeftBar: {
      type: Boolean,
      default: true,
    },
    slideRightBarIn: {
      type: Boolean,
      default: undefined,
    },
    leftClassName: {
      type: String,
      default: "",
    },
    rightClassName: {
      type: String,
      default: "",
    },
    mainContentClass: {
      type: String,
      default: "",
    },
    patternLab: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    classObject(){
      return {
        noSideBar: !this.showRightBar && !this.showLeftBar
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#main-content {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 52px;
  &.computed-width {
    width: calc(100% - 30rem);
  }
  &.content-one-bar {
    width: calc(100% - 15rem);
    margin-right: 0;
  }
  &.grayBg {
    background-color: $spBg;
  }
}

#wrapper {
  display: flex;
  min-height: calc(100vh);
}

//point from which sidebar is showing on top
@media only screen and (max-width: 1023px) {
  #wrapper {
    flex-wrap: wrap;
    &.noSideBar{
      #main-content{
        margin-top: toRem(52);
      }
    }
  }
  #main-content {
    width: 100% !important;
    order: 3;
    margin-top: 0;
    max-width: unset !important;
  }
}
//point where menu is changing
@media only screen and (max-width: 800px){
  #wrapper{
    &.noSideBar{
      #main-content{
        margin-top: toRem(75);
      }
    }
  }
}
</style>