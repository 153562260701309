var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-wrapper",class:[
    _vm.side,
    {
      hidden: !_vm.display,
      shown: _vm.display,
      slideSideBarIn: _vm.slideSideBarIn,
      slideSideBarOut: _vm.slideSideBarIn === false,
    } ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }