<template>
  <div
    class="sidebar-wrapper"
    :class="[
      side,
      {
        hidden: !display,
        shown: display,
        slideSideBarIn: slideSideBarIn,
        slideSideBarOut: slideSideBarIn === false,
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Layout-Sidebar",
  props: {
    side: {
      type: String,
      required: true,
    },
    display: {
      type: Boolean,
      default: true,
    },
    slideSideBarIn: {
      type: Boolean,
    },
  },
  data() {
    return {
      hasTriggered: false,
    };
  },
  watch: {
    display() {
      this.hasTriggered = true;
    },
  },
};
</script>

<style lang="scss">
.sidebar-wrapper {
  margin-top: 52px;
  width: toRem(234);
  flex-shrink: 0;
  padding: toRem(16);
  min-height: 100%;
  &.patternLab {
    padding: toRem(16) 0 0 toRem(16);
  }
  &.l {
    border-right: 1px solid $separator;
  }
  &.r {
    border-left: 1px solid $separator;
    &.fixed {
      right: 0;
    }
  }
  &.fixed {
    position: fixed;
  }
  &.slideSideBarIn {
    animation: animateSidebar 0.5s;
  }
  &.slideSideBarOut {
    animation: hideSidebar 0.5s forwards;
  }
  &.hidden {
    display: none;
  }
  &.shown {
    display: block;
  }
}
@media only screen and (max-width: 1023px) {
  .sidebar-wrapper {
    width: 100%;
    position: relative !important;
    min-height: unset;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid $separator;
    &.r {
      order: 2;
      margin-top: 10px;
    }
    &.l {
      margin-top: 100px;
    }
  }
}

@keyframes animateSidebar {
  0% {
    width: 0;
  }
  100% {
    width: toRem(254);
  }
}
@keyframes hideSidebar {
  0% {
  }
  100% {
    width: 0;
    display: none;
    padding: 0;
    opacity: 0;
  }
}
</style>
